.fullscreen-gallery-container {
  height: 100vh;
  width: 100vw;
}

.fullscreen-gallery-container .btn-close {
  left: 1.5rem;
  padding: 2rem;
  position: absolute;
  top: 0.7rem;
}

.fullscreen-gallery-container {
  background: #edeff0;
  display: flex;
  justify-content: center;
  overflow: scroll;
}

.fullscreen-gallery-container .image-wrapper img {
  height: 100vh;
}

.fullscreen-gallery-container .bullets {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: absolute;
  right: 8vw;
}

.fullscreen-gallery-container .bullets div {
  background: #000;
  margin: 1rem;
  padding: 0.0625rem 0.1875rem;
}

.fullscreen-gallery-container .bullets .active {
  padding: 1.5rem 0.1875rem;
}

/* 4k vertical adjustments*/
@media (orientation: portrait) {
  .fullscreen-gallery-container .image-wrapper img {
    height: unset;
    width: 100vw;
  }

  .fullscreen-gallery-container .btn-close {
    left: 50px;
    padding: 1rem;
    position: absolute;
    top: 1215px;
    border: 1px solid #808080;
    background: rgba(245, 243, 243, 0.3);
    display: flex;
  }

  .fullscreen-gallery-container .background-loader {
    min-height: 70vh;
  }
}
