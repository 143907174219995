.empty-bitm p {
  font-size: 1.32rem;
  line-height: 1.8rem;
  padding-top: 1rem;
}

.empty-bitm {
  display: flex;
  height: 50rem;
  justify-content: center;
  padding-top: 20rem;
}

.empty-bitm .cart-icon {
  padding-bottom: 3.68rem;
}

.empty-bitm .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: -10rem;
  /* padding: 2rem; */
  /* padding-bottom: 0; */
  padding-left: 0.5rem;
}

.empty-bitm__btn-container {
  padding: 2rem;
}

.empty-bitm .bitm-icon {
  width: 4rem;
  height: 4rem;
}

.empty-bitm h4 {
  padding: 1rem 0;
  line-height: 120%;
}
/* 4k vertical adjustments*/
@media (orientation: portrait) {
  .shopping-bag .empty-bitm {
    height: 30rem;
  }
}
