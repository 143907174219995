.gm-style-iw,
.gm-style-iw-c {
  padding: 0 !important;
}

.map-info-window {
  padding: 1rem;
}

.map-info-window .store-name {
  font-weight: bold !important;
  line-height: 128%;
}

.map-info-window .store-address {
  line-height: 128%;
  margin-top: 0.1rem;
}
