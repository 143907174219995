.events-page-wrapper {
  margin: 0;
  margin: 0 auto;
  position: relative;
  width: 100%;
  margin-top: 4.5rem;
}

.events .tile {
  align-items: flex-end;
  background-color: white !important;
  background-image: url("../../assets/images/placeholder_image.png");
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  height: 44vw;
  justify-items: center;
  position: relative;
  min-width: 40vw;
  max-width: 44vw;
  overflow: hidden;
}

.events .event-name-block {
  margin: 0;
  padding: 0;
  margin-left: 2rem;
  margin-bottom: 1rem;
  /* margin: 3rem 3rem; */
}

.events .event-name-block .sub-heading {
  text-transform: uppercase;
  font-weight: 700;
}

.events .event-name-block .sub-heading span {
  background-color: white;
  white-space: pre-wrap;
  padding-right: 10px;
  padding-left: 10px;
}
.events .event-name-block .sub-heading span span {
  position: relative;
  left: 10px;
  line-height: 230%;
  text-align: left;
}

.tile img {
  height: 43vw;
  width: 43vw;
  object-fit: cover;
}

.tiles-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: flex-start;
  margin-left: 9rem;
}

.secondary-masthead {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 12.5%,
    rgba(0, 0, 0, 0.8) 100%
  );
  display: flex;
  flex-direction: column;
  gap: 5.56px;
  height: 33%;
  justify-content: flex-end;
  position: absolute;
  width: 100%;
  margin-bottom: 0.25rem;
}

.secondary-masthead .container {
  margin: 1.5rem 1rem;
}

.secondary-masthead .title {
  background-color: white;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 2px;
  padding: 6px 13px;
  text-transform: uppercase;
}

.secondary-masthead .subtitle {
  font-weight: 400;
}

.container-with-gradient {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 12.5%,
    rgba(0, 0, 0, 0.8) 100%
  );
}

.events .heading {
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
}

.events .sub-heading {
  margin-top: 0.75rem;
  margin-bottom: 6rem;
  text-align: center;
  font-size: 1.31rem;
}

@media (orientation: portrait) {
  .events .secondary-masthead {
    width: 98%;
  }

  .events .tiles-container {
    gap: unset;
  }

  .events .tile {
    width: 44vw;
    max-width: unset;
    overflow: unset;
  }
}
