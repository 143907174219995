.gl-tab {
  display: flex;
  height: 4rem;
  justify-content: center;
  padding-top: 0.2rem;
  width: 70.6vw;
  /* border: 3px dotted cyan; */
}

.gl-tab.sticky {
  left: 0;
  position: fixed;
  top: 0;
  /* z-index: 230; */
}

.gl-tab__item {
  font-size: 1rem;
}
