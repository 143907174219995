.primary-menu {
  position: relative;
}

.primary-menu .highlighted,
.secondary-menu .highlighted {
  /* border-left: 0.25rem solid red; */
  background: #000;
  background: invert(0);
}

.primary-menu .feature-unavailable {
  opacity: 0.3;
}

.primary-menu .icon-container {
  position: relative;
}
.primary-menu .cart-count {
  color: #fff;
  background: #000;
  margin: 0px !important;
  width: 1.4rem;
  padding: 0.15rem !important;
  position: absolute;
  right: 0.5rem;
  bottom: -0.3rem;
  border: 1px solid #000;
  font-weight: bold;
  font-size: 1rem;
}

.primary-menu .highlighted .cart-count {
  color: #000;
  background: #fff;
}

.primary-menu .debug {
  border: 2px dotted cyan;
  font-size: 1rem;
}

.debug-info-active-locale {
  opacity: 0.5;
  color: #d0d0d0;
  position: fixed;
  bottom: 2rem;
}

.app-version-info {
  padding: 1rem;
  word-break: break-all;
  opacity: 0.2;
  font-size: 1rem;
}

.app-version-info div {
  padding: 0 !important;
  margin: 0 !important;
}

.storage-used-info {
  font-size: 0.8rem !important;
  position: fixed;
  left: 0;
  bottom: 10px;
  word-break: break-all;
  opacity: 0.06;
  font-size: 1rem;
  padding: 1rem;
}

.sidebar-menu-flag img {
  width: 3rem;
  margin-top: 1rem;
}

.sidebar-menu-flag img {
  border: 1px solid #e9e5e5;
}

.multi-lang-flag {
  border: 1px solid grey;
}

/* 4k vertical adjustments*/
@media (orientation: portrait) {
  .sidebar-menu-flag img {
    width: 5rem;
  }

  .storage-used-info {
    font-size: 1.5rem !important;
  }
}
