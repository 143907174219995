.employeid-input-container {
    display: flex;
    flex-direction: column;
    row-gap: 0.75rem
}

.employeid-header {
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 700;
}

.employeid-description {
    font-size: 1rem;
    color: #9A9B9B;
}

.employeeid-error {
    font-size: 1rem;
    color: red;
}

.employeid-wrapper {
    display: flex;
    gap: 12px;
    max-width: 30vw;
}

.employeid-char { 
    display: flex;
    width: 38px;
    height: 48px;
    flex-direction: row;
    justify-content: center;
    background-color: #d3d7da;
    align-items: center;
    background-color: #d3d7da;
    font-size: 1.15rem;
    font-weight: 700;
}

.enable-employeeid-change {
    border-bottom: 1px solid #000;
    padding-bottom: 0.2rem;
    display: flex;
    font-size: 1.125rem;
    font-weight: 700;
    letter-spacing: 0.08rem;
    text-transform: uppercase;
    width: fit-content;
    margin-bottom: 2rem;
}

.enable-employeeid-change {
    margin-top: 2rem
}

@media (orientation: landscape) {
    .keyoboard-margin {
        margin-bottom: 270px;
    }
}
