.order-summary .title {
  width: 70%;
}

.total-amount {
  font-size: 1.312rem !important;
  font-family: "AdineuePRO", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  text-transform: uppercase;
}
