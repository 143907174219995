.landing-page-wrapper {
  margin: 3rem 9rem;
}

.landing-page-wrapper .label {
  align-items: center;
  display: flex;
  font-size: 2.25rem;
  height: 100%;
  justify-content: center;
  position: relative;
  text-transform: uppercase;
  font-weight: 700;
}

.landing-page-wrapper .section {
  margin-bottom: 4.5rem;
}

.landing-page-wrapper .content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 1.5rem 0;
}

.landing-page-wrapper .content-no-wrap {
  flex-wrap: nowrap;
  overflow-x: scroll;
}

.landing-page-wrapper .content .item {
  background-color: black;
  background-position: center;
  background-repeat: no-repeat, repeat;
  color: white;
  height: 100%;
  height: 21.6rem;
  margin-left: 1.25rem;
  width: 32.3%;
  min-width: 30%;
  background-size: cover;
}

.landing-page-wrapper .content .item.black-text {
  color: #000;
}

.landing-page-wrapper .content .item:first-child {
  margin-left: 0;
}

.landing-page-wrapper .content .item.wide {
  width: 66.1%;
}

.landing-page-wrapper .top-left-aligned {
  align-items: flex-start;
  padding: 2.25rem 1.5rem;
  text-align: left;
  font-weight: 700;
}

.landing-page-wrapper .item-narrow {
  height: 20.2vw !important;
  min-width: unset !important;
  width: 20.2vw !important;
}

.landing-page-wrapper .content.left-aligned {
  justify-content: space-between;
}

.landing-page-wrapper .cta-logo {
  bottom: 1.5rem;
  left: 1.5rem;
  position: absolute;
}

.landing-page-wrapper h2.heading {
  text-align: left;
}

@media (orientation: portrait) {
  .landing-page-wrapper .item-narrow {
    height: 27.8rem !important;
    width: 27.8rem !important;
  }
}
