.employee-id__content {
  background: #fff;
  min-height: 30vh;
  width: 40vw;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.employee-id__content .body {
  margin-top: 2rem;
}

.employee-id__content .lower {
  margin-top: 2rem;
}

.employee-id__content .gl-cta {
  width: 100%;
  margin-top: 1rem;
}

.employee-id__content .gl-cta--secondary-light {
  height: 4rem;
  width: 100%;
}

.employee-id__content .disclaimer {
  color: #767677;
  font-size: 1.125rem;
  margin-top: 1.5rem;
}

.employee-id__content .body {
  margin-top: 4rem;
}

.error-text {
  color: red;
}

/* 4k vertical adjustments*/
@media (orientation: portrait) {
  .employee-id__content {
    min-height: 20vw;
  }
}
