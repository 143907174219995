.site-header {
  display: flex;
  align-items: center;
  padding: 2.5rem 2.5rem;
  background: #000;
  width: 100vw;
}

.site-header .logo {
  height: 50px;
  width: 50px;
  padding-top: 0.5rem;
}

.site-header .label {
  color: white;
  margin-left: 42px;
  font-size: 24px;
  line-height: 28px;
  text-transform: uppercase;
}

/* 4k vertical adjustments*/
@media (orientation: portrait) {
  /* moving sidebar a bit down to match new layout when header appears */
  .side-panel {
    /* top: 128px; */
    margin-top: 34.98vh;
  }
}
