.pdp__wrapper {
  -ms-overflow-style: none;
  align-items: left;
  background: #fff;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: left;
  left: 0;
  overflow: scroll;
  overflow-y: scroll;
  position: fixed;
  scrollbar-width: none;
  top: 0;
  width: 71.5vw;
  z-index: 99;
}

.pdp__wrapper p {
  font-size: 1.125rem;
  line-height: 128%;
}

.pdp__wrapper::-webkit-scrollbar {
  display: none;
}

.pdp__wrapper .left {
  position: relative;
}

.pdp-container {
  height: 100vh;
  overflow: scroll;
  width: 70vw;
}

.gallery-wrapper {
  background: #edeff0;
  display: flex;
  justify-content: center;
}

.large-image {
  height: 57.4%;
  width: 57.4%;
}

.large-image img {
  margin-top: -100px;
  width: 100%;
}

.large-image {
  display: 1none;
}

.pdp-back-button {
  padding: 2.7rem 3.5rem;
  position: absolute;
  top: 0;
  z-index: 100;
}

.review-section {
  padding-top: 4rem;
}

.debug-pdp-product-list-stack {
  background: #fff;
  border: 3px dotted cyan;
  color: #808080;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  left: 1rem;
  position: absolute;
  top: 5rem;
  z-index: 10;
}

.debug-pdp-product-list-history > div {
  margin: 0.2rem;
}

/* 4k vertical adjustments*/
@media (orientation: portrait) {
  .pdp__wrapper {
    width: 100vw;
  }

  .pdp-back-button {
    top: 1100px;
    margin-left: 1.5rem;
  }
}
