.tap-to-get-started-title {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  z-index: 1000;
  animation: blinkingTaptoGetStarted 12s infinite;
}

@keyframes blinkingTaptoGetStarted {
  0% {
    color: #fff;
  }
  80% {
    color: #fff;
  }
  90% {
    color: transparent;
  }
  99% {
    color: transparent;
  }
  100% {
    color: transparent;
  }
}

.tap-to-get-started-animation {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  z-index: 1000;
  transform: scale(0.5);
}

.tap-to-get-started-title h1 {
  font-size: 100px;
  font-weight: 700;
  line-height: 100%;
  text-transform: uppercase;
}

/* 4k vertical adjustments*/
@media (orientation: portrait) {
  .tap-to-get-started-title h1 {
    font-size: 190px;
    width: 80%;
  }

  .tap-to-get-started-animation {
    transform: unset;
  }
}
