.checkout-confirmation_container {
  display: flex;
}

.checkout-confirmation__content_left {
  width: 25vw;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #d3d7da;
}

.checkout-confirmation__content_left .qr_code {
  margin-top: 2.5rem;
}

.checkout-confirmation__content_left .instruction {
  margin-top: 2rem;
}

.checkout-confirmation__content_right {
  background: #fff;
  min-height: 20rem;
  width: 25vw;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.checkout-confirmation__content_right .body {
  margin-top: 2rem;
}

.checkout-confirmation__content_right .gl-cta {
  width: 100%;
  margin-top: 1rem;
}

.checkout-confirmation__content_right .gl-cta--secondary-light {
  height: 4rem;
  width: 100%;
}

.checkout-confirmation__content_left__wide {
  width: 35vw;
}

.checkout-confirmation_container .debug_qr_url {
  color: blue;
  word-break: break-all;
}

.employee-checkout-container {
  padding: 2rem;
  padding: 2rem;
  padding-top: 0;
  display: flex;
  flex-direction: column;
}

.employee-checkout {
  border-bottom: 1px solid #000;
  padding-bottom: 0.2rem;
  display: flex;
  font-size: 1.125rem;
  font-weight: 700;
  letter-spacing: 0.08rem;
  text-transform: uppercase;
  width: fit-content;
  margin-bottom: 2rem;
}

.qrcode-background { 
  width: 370px;
  height: 370px;
  background-color: #d3d7da;
}

.arrow-back {
  margin-right: 2rem;
}

.checkout-confirmation-title {
  display: flex;
  align-items: center;
  gap: 1rem;
}

@media (orientation: landscape) {
  .align-items-unset {
    align-items: unset;
  }
}