.nearbystore-availability-container {
  margin-right: 2rem;
}

.nearbystore-availability-container .unavailable {
  color: #767677;
}

.nearbystore-availability-container .no-availability-info-container {
  margin-top: 1rem;
}

.nearbystore-availability-container .no-availability-info-container div {
  margin-bottom: 0.5rem;
}
