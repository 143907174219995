.new-back-button {
  background-color: #000;

  width: 5rem;
  height: 5rem;
  position: fixed;
  top: 49vh;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-back-button img {
  width: 50%;
  height: auto;
}

@media (orientation: portrait) {
  .new-back-button {
    width: 9rem;
    height: 9rem;
  }
}
