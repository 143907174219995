.shopping-bag-container {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  opacity: 1;
  font-size: 2rem;
}

.shopping-bag {
  background: #fff;
  flex-direction: column;
  height: 100vh;
  opacity: 1;
  width: 29.45vw;
  z-index: 100;
}

.shopping-bag .header {
  padding: 2rem;
}

.shopping-bag .item-list {
  background: #fff;
  flex-direction: column;
  padding: 0;
  padding-bottom: 0;
}

.bottom-border {
  border-bottom: 1px solid #ebedee;
}

.shopping-bag h4 {
  letter-spacing: 0.125rem;
  margin: 0;
}
