.map-container {
  display: flex;
  margin-bottom: 20px;
  margin-top: 2rem;
  height: 40vw;
  overflow: scroll;
  background-color: rgb(237, 235, 232);
}

.map-container .location-list {
  background: #fff;
  min-width: 25rem;
  width: 31.5%;
  min-height: 35vw;
  /* border: 1px dotted blue; */
}

.map-container .location-list {
  background: #fff;
  min-width: 25rem;
  width: 32.5%;
  min-height: 35vw;
  /* border: 1px dotted blue; */
  padding-top: 0.6rem;
}

.search-input-container {
  text-transform: unset;
  display: flex;
  align-items: center;
}

.search-input-container .gl-form-item {
  width: 80%;
  /* margin-left: 2rem; */
}
.search-input-container .gl-input__label {
  text-transform: unset;
}

.search-input-container .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}

.locations-loader {
  display: flex;
  justify-content: center;
  padding-top: 50%;
}

.no-results-label {
  padding: 2rem;
}

.no-results-label p {
  font-size: 1.3125rem !important;
}
