.size-guidance-container {
  width: 80vmin;
  height: 80vmin;
}
.size-guidance-container iframe {
  width: 100%;
  height: 100%;
  border: 0;
  margin: 0;
  padding: 1.5rem;
}
