.empty-help p {
  font-size: 1.32rem;
  line-height: 1.8rem;
  padding-top: 1rem;
}

.empty-help {
  display: flex;
  height: 50rem;
  justify-content: center;
  padding-top: 20rem;
}

.empty-help .cart-icon {
  padding-bottom: 3.68rem;
}

.empty-help .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: -10rem;
  padding-left: 0.5rem;
}

.empty-help__btn-container {
  padding: 2rem;
}

.empty-help .help-icon {
  width: 4rem;
  height: 4rem;
}

.empty-help h4 {
  padding: 1rem 0;
  line-height: 120%;
}

.empty-help__btn-container .loader-wrapper {
  width: 4rem;
  height: 4rem;
  margin-top: 1rem;
}
.empty-help__btn-container .loader-wrapper .gl-loader {
  width: 100%;
  height: 100%;
}

.cta-create-help-request {
  padding-top: 2rem;
}
/* 4k vertical adjustments*/
@media (orientation: portrait) {
  .shopping-bag .empty-help {
    height: 30rem;
  }
}
