.bitm-wrapper {
  padding: 0;
}

.bitm {
  background: #eceff1;
  display: flex;
  margin-bottom: 0.125rem;
  padding: 0;
  padding-bottom: 1rem;
  padding-top: 1rem;
  width: 100%;
}

.bitm .product-details {
  padding: 1rem;
  width: 100%;
}

.bitm .image-container {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 30%;
}

.bitm img {
  height: 10rem;
  margin-left: 1rem;
  width: 10rem;
}

.bitm .product-details .wrapper {
  width: 100%;
}

.bitm .product-details .wrapper div {
  font-size: 1.125rem !important;
}

.bitm .product-details .title {
  width: 90%;
}

.bitm .product-details .sale {
  background: #c53622 !important;
  color: #fff;
}

.bitm .product-details {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bitm .product-details .price {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0.75rem 1rem 1.5rem;
  width: 95%;
}

.bitm .product-details .price > div {
  padding: 0.2rem;
}

.bitm .action-panel {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0.75rem 1rem;
  width: 95%;
}

.bitm .action-panel .left,
.action-panel .right {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.bitm .action-panel .left div,
.action-panel .right div {
  margin-left: 0.2rem;
  text-transform: uppercase;
}

.bitm .product-details h4 {
  font-family: AdihausDIN;
  font-size: 18px;
  font-size: 1.125rem;
  letter-spacing: 2px;
  line-height: 133%;
  margin-bottom: 0;
  text-transform: uppercase;
}

.bitm .action-panel .gl-cta {
  height: 2.125rem;
  min-height: unset;
  width: 2.125rem;
}

.bitm .action-panel .gl-cta .gl-icon {
  height: 50%;
  width: 50%;
}

.cta-items .bold {
  font-size: 1.313rem;
  font-weight: 700;
}

.cta-items {
  display: flex;
  justify-content: space-between;
  width: 99%;
}

.cta-items .left {
  align-items: center;
  display: flex;
}

.checkout-cta {
  margin: 0 2rem;
}

.checkout-cta .gl-cta__content {
  font-size: 1rem;
}

.bitm-container {
  display: flex;
  flex-direction: column;
  /* height: 91%; */
  justify-content: space-between;
  overflow: scroll;
}

.bitm-status-container .lastupdated-info {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.bitm-status-container .lastupdated-info div {
  margin-left: 0.5rem;
  text-transform: uppercase;
}
