.rating-reviews-container {
  margin: 0 auto;
  /* width: 66.5%; */
  overflow: scroll;
  padding: 1rem 0;
  min-height: 500px;
  margin-bottom: 100px;
}

.rating-reviews-container {
  background: #fff;
}

.rating-reviews-container .heading {
  padding: 0 1rem;
}

.rating-reviews-container .row {
  display: flex;
}

.rating-reviews-container .row > div {
  padding: 1rem;
}

.rating-reviews-container .summary {
  width: 30%;
}

.rating-reviews-container .reviews {
  width: 70%;
}

.star-ratings {
  background: #2ada71;
  padding: 0.5rem 1rem;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.star-ratings > div {
  margin: 0.2rem;
}

.avg-rating-label {
  font-size: 1.875rem;
}

.ratings_averages {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.ratings_averages > div {
  margin: 1rem;
}

.review-item {
  margin-bottom: 3rem;
}

.star-ratings .gl-star-rating__star {
  height: 1.2rem;
  width: 1.2rem;
}

.rating-reviews-container .reviews {
  /* padding-top: 3.5rem !important; */
}

.reviews p {
  margin: 0;
  margin-bottom: 0.5rem;
}

.reviews__more-details {
  color: #767677;
}

.reviews__more-details {
  margin-top: 1rem;
}

.reviews .read-more {
  text-decoration: underline;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.rating-reviews-container h4 {
  margin: 0;
  padding-bottom: 1rem;
}

.review_offset {
  padding-top: 3.125rem !important;
}
