.ugc-container {
  margin: 0 auto;
  /* width: 66.5%; */
  overflow: hidden;
  padding: 1rem 0;
  /* border: 1px dotted cyan; */
}

.ugc-gallery-container img {
  width: 18.5rem;
  height: 18.5rem;
  padding: 0.5rem;
  object-fit: cover;
}

.ugc-overlay-content {
  width: 30vw;
  height: 100vh;
  object-fit: cover;
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

.ugc-overlay-content img {
  width: 100%;
  height: auto;
  object-fit: cover;
  padding: 1rem;
}
