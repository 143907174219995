.product-card-skeleton {
  background: #ebedee;
  border: 1px solid #fff;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
}

@media (min-width: 960px) {
  .product-card-skeleton {
    border: 0.24rem solid #fff;
  }
}

.image_placeholder {
  padding-bottom: 99.5%;
  position: relative;
  width: 99%;
}

.category_placeholder {
  background-color: #ebedee;
  margin-left: 1rem;
  margin-top: 0.6rem;
  padding-top: 0.8rem;
  width: 65%;
}

.name_placeholder {
  background-color: #ebedee;
  margin-left: 1rem;
  margin-top: 0.2rem;
  padding-top: 0.8rem;
  width: 30%;
}

.price_placeholder {
  background-color: #ebedee;
  margin-bottom: 1rem;
  margin-left: 1rem;
  margin-top: 0.08rem;
  padding-top: 0.8rem;
  width: 25%;
}

.gl-product-card__media .gl-product-card__media--base {
  min-height: 100%;
}

.placeholder___33zhp {
  padding-bottom: 100%;
  position: relative;
  width: 100%;
}
