.product-sidepanel {
  padding-top: 1rem;
}

.product-sidepanel .pre-header {
  display: flex;
  justify-content: space-between;
}

.product-sidepanel .pre-header__rating {
  display: flex;
}

.product-sidepanel .pre-header__rating .count {
  font-weight: 700;
  margin-left: 0.5rem;
  text-decoration: underline;
}

.product-sidepanel h4 {
  font-size: 2rem;
  line-height: 120%;
  margin: 0 0 0.3rem 0;
}

.product-sidepanel .bold {
  font-weight: 700;
}

.product-sidepanel .underline {
  text-decoration: underline;
}

.product-sidepanel .price {
  margin-top: 0.3rem;
}

.product-sidepanel .button-wrapper {
  margin-top: 0.4rem;
  width: 75%;
}

.product-sidepanel .gl-cta--secondary,
.product-sidepanel .gl-cta--primary {
  width: 100%;
}

.product-sidepanel .gl-cta--primary {
  margin-left: 0.2rem;
  padding: 2rem 1.5rem;
}

.product-sidepanel .button-wrapper.disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

.pdp-qr-code-section {
  margin-top: 1rem;
}

.pdp-qr-code {
  margin-top: 0.8rem;
}

.product-sidepanel {
  background: #fff;
  display: 1none;
  margin-right: 3.8vw;
  padding: 1.3rem 2rem;
  width: 25.7vw;
}

.product-sidepanel section {
  padding-bottom: 0.65rem;
  padding-top: 0.65rem;
}

.product-side-panel-container {
  display: flex;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
}

.product-side-panel-container .content {
  background: #fff;
  height: 100vh;
  overflow: scroll;
}

.product-side-panel-container {
  border-left: 1px solid #ebedee;
  display: flex;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
}

.qr-url-debug-text {
  background: yellowgreen;
  width: 400px;
  word-break: break-all;
  margin-top: 1rem;
}

.add-to-cart-button {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.add-to-cart-button img {
  height: 2.5rem;
  width: 2.5rem;
}

.product-side-panel-container .gl-price-item {
  font-size: 1.5rem;
}

.product-side-panel-container {
  font-size: 1.125rem;
}

.product-side-panel-container .size-guidance-link {
  /* line-height: 120%; */
  text-decoration: underline;
}

.product-side-panel-container .bitm_btn {
  margin-top: 2rem;
}

.product-side-panel-container .btn-container {
  margin-top: 2rem;
}

.size-info-header {
  display: flex;
  justify-content: space-between;
}

.size-info-legend-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.size-info-legend-container .icon-text {
  display: flex;
}

.size-info-legend-container img {
  padding-right: 0.5rem;
  width: 1.8rem;
  margin-bottom: -0.2rem;
}

.product-sidepanel .link-btn {
  font-weight: bold;
  text-decoration: underline;
  display: block;
}

.product-sidepanel .gl-loader {
  width: 2rem;
  height: 2rem;
}

.retail-online-size-selector {
  margin: 1rem;
}

.retail-online-size-selector .gl-tab {
  width: unset;
}

/* 4k vertical adjustments*/
@media (orientation: portrait) {
  .product-side-panel-container {
    top: 1215px;
  }

  .pdp__wrapper .navigation-wrapper .gl-tab {
    width: 69.6vw;
  }

  .product-sidepanel {
    margin-right: 5.5vw;
  }
}
