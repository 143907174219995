.store-availability {
  width: 100%;
  height: calc(100% - 5rem);
  display: flex;
}

.locations-list,
.locations-map {
  padding-right: 2rem;
}

.locations-list {
  width: 40%;
  min-height: 35vw;
}

.locations-map {
  width: 60%;
}

.available-sizes {
  width: 39.25rem;
  border: 2px dotted blue;
}

.locations-container__item .item {
  display: flex;
}

.locations-container__item .item .pin-city {
  margin-right: auto;
}

.locations-container__item .item .icon-container {
  display: flex;
  align-items: flex-start;
}

.locations-container__item .item .icon-container img {
  margin-left: 10px;
}

.store-availability .gl-input .gl-input__field {
  border: none;
  border-bottom: 1px solid #eceff1;
  padding: 2rem;
}

.locations-map .map {
  height: 100%;
}

.list-map-locations {
  min-height: unset;
}

#list-map-locations-container {
  padding: 0;
  padding-top: 1.5rem;
}

.selected {
  font-weight: bold;
}

.nearby-store-name {
  width: 80%;
  line-height: 128%;
}

.nearby-stores-search-icon,
.nearby-stores-input-container {
  padding-left: 0 !important;
}

.nearby-stores-location-icon {
  padding-left: 3rem !important;
  padding-right: 0 !important;
}
