.product-sidepanel .sizegrid-container {
  display: flex;
  flex-wrap: wrap;
  border-left: 1px solid #c8cbcc;
}

.border_top {
  border-top: 1px solid #c8cbcc;
}

.product-sidepanel .sizegrid-container div {
  min-width: 25%;
  height: 3rem;
  border-right: 1px solid #c8cbcc;
  border-bottom: 1px solid #c8cbcc;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 0 0.4rem;
  font-size: 1rem;
  flex-grow: 1;
  max-width: 20%;
}

.product-sidepanel .sizegrid-container .size-selected {
  background: #000;
  color: #fff;
  font-weight: bold;
}

.product-sidepanel .sizegrid-container .available-instore {
  position: absolute;
  left: 0.313rem;
  top: 0px;
  /* opacity: 0.2; */
}

.product-sidepanel .skeleton-size-item {
  background: #fff;
}

.product-sidepanel .skeleton-size-item div {
  padding: 0.2rem;
  height: 25%;
  width: 40%;
  background: #ebedee;
}

.debug-size-stock-level {
  background: #d0d0d0;
  height: 20px !important;
  position: absolute;
  color: green;
  top: 0px;
  right: 0px;
}

.product-sidepanel .debug-msg {
  color: green;
  border: 2px dotted cyan;
  padding: 1rem;
  margin-top: 1rem;
}

.product-sidepanel .unavailable-sku {
  color: red;
  background-color: #f0f0f0;
}
