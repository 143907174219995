.creating-order .in-progress {
  width: 31.5rem;
  height: 23.8rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.creating-order .in-progress .info {
  font-weight: bold;
  text-transform: uppercase;
  padding-top: 3rem;
  letter-spacing: 0.125rem;
}

.creating-order .btn-next-container {
  padding: 0.5rem;
  border: 1px dotted cyan;
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
}

.creating-order .btn-next-container div {
  margin-right: 1rem;
  text-decoration: underline;
}

.creating-order .static-progress-bar {
  border-bottom: 1px solid #000;
  width: 40%;
}

.hidden {
  display: none !important;
}

.creating-order__success {
  width: 57rem;
  height: 55rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.creating-order__success h4 {
  font-size: 2.5rem;
  margin-top: 2.93rem;
}

.creating-order__success .session-end-info-container {
  color: #0286cd;
  padding: 1rem;
  background: #eceff1;
  width: 100%;

  text-transform: uppercase;
  letter-spacing: 0.125rem;
  font-family: "adineuePro";
  text-align: center;
  font-size: 1.31rem;
  margin-top: 2rem;
  margin-bottom: 2.31rem;
}

.creating-order__success .order-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.31rem;
  padding-top: 2rem;
}

.creating-order__success .order-details div {
  padding-bottom: 1.5rem;
}

.creating-order__success .checkmark-confirm img {
  width: 4.06rem;
  height: 4.06rem;
}

.creating-order__success .label {
  font-weight: bold;
}

.creating-order__success .cta-container {
  position: absolute;
  bottom: 2rem;
  /* width: 30%; */
}

.creating-order__error {
  padding: 15px;
  text-align: center;
  color: red;
}

.creating-order .overlay-error-container > div {
  padding: 1rem;
}