.plp-heading {
  display: flex;
  margin: auto;
  margin: 0 5rem;
  padding: 3rem 8rem;
}

.plp-heading .item {
  font-size: 1.25rem;
  margin-right: 0.5rem;
}

.plp-heading .item.icon {
  margin-right: 2rem;
  display: flex;
}

.plp-heading .item.result-count {
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: #767677;
  padding-bottom: 0.2rem;
  padding-left: 0.5rem;
}

.plp-heading .item.heading-text {
  display: flex;
}

.plp-heading .item.heading-text .prefix {
  margin-right: 0.5rem;
}

.title-loading {
  background: #f5f6f6;
  padding: 0.25rem;
  padding-right: 260px;
}
