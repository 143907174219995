#app-keyboard {
  visibility: visible;
  transform: translate(-580.571px, -1488px);
  /* border: 1px solid red;
  background: rgba(0, 0, 0, 0.1); */
  align-items: center;
  width: 66.375rem;
  position: fixed;
  z-index: 999;
  font-size: 1.3125rem !important;
  margin: 3%;
  padding: 1rem;
}

.hg-lang,
.hg-bksp-close {
  max-width: 82px;
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  align-items: end !important;
}

.hg-lang > span > img {
  height: 1.5rem;
  width: auto;
}

.hg-button {
  height: 3rem !important;
}

.hg-bksp-close {
  align-items: center !important;
}

.hg-theme-default {
  font-family: "ITCFranklinGothic-BookCompressed", Helvetica, Arial, sans-serif;
}

.app-keyboard-safe-line {
  height: 12px;
  background: transparent;
}

.drag-icon {
  position: absolute;
  right: calc(100% - 1rem);
  bottom: calc(100% - 1rem);
  background: rgba(255, 255, 255, 0.8);
  /* padding: 15px; */
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--gl-color-primary);
  /* border-radius: 50%; */
}

#app-keyboard > div.drag-icon > svg {
  fill: black;
  width: 1.5rem;
  height: 1.5rem;
}

@media (orientation: landscape) {
  #app-keyboard {
    width: 66.375rem;
    right: 25vw;
    margin: 0;
  }
}

.disabled-flag {
  display: none !important;
}
