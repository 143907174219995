.event-container {
  min-height: 100vh;
}

.event-container .circle-container {
  background-color: white;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.event-container .container-left {
  width: 50%;
}

.event-container .container-right {
  width: 50%;
}

.event-container .description {
  font-size: 2.06rem;
  padding-bottom: 0.18rem;
  position: relative;
  display: flex;
  flex-direction: column;
}

.event-container .details-container {
  display: flex;
  margin: 0 auto;
  padding-top: 4rem;
  width: 72%;
}

.event-container .details-container p {
  font-size: 2.05rem;
  margin-bottom: 2rem;
  line-height: 2.9rem;
}

.event-container .dot {
  border: 0.15rem solid #767677;
  border-radius: 50%;
  display: inline-block;
  height: 3.5rem;
  margin: 1rem;
  width: 3.3rem;
}

.event-container .heading {
  font-size: 3.5rem;
  text-transform: uppercase;
  text-align: left;
  letter-spacing: 0.29rem;
  line-height: 120%;
  padding-right: 1rem;
  word-break: break-word;
}

.event-container .icon {
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.event-container .image-placeholder {
  background: #d0d0d0;
  background-color: #fff;
  background-image: url("../../../assets/images/placeholder_image.png");
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  height: 31.6vh;
  justify-content: center;
  width: 100vw;
}

.event-container .image-placeholder > div {
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.event-details-container {
  position: relative;
}

.event-details-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.event-container .progress-container {
  display: flex;
  height: auto;
  min-width: 100px;
  position: relative;
}

.event-container .progress-container-left {
  height: 100%;
  min-width: 2px;
  width: 50%;
}

.event-container .qr-code-wrapper {
  align-items: center;
  display: flex;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 2.5rem;
}

.event-container .qr-code-wrapper canvas {
  width: 250px !important;
  height: auto !important;
}

.event-container .qr-code-wrapper .highlight-image {
  height: auto;
  margin: 3rem;
  width: 35vw;
}

.event-container .qr-code-wrapper .qr-code-image {
  background: white;
  height: 23.8rem;
  width: 23.8rem;
}

.event-container .qr-code.label {
  font-size: 3rem;
  padding: 2rem 0;
  text-transform: uppercase;
}

.event-container .row {
  display: flex;
  min-height: 210px;
}

.event-container .sub-heading {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 2.63rem;
  font-weight: 700;
  margin-top: 3.43rem;
  margin-bottom: 3.43rem;
  text-transform: uppercase;
  text-align: left;
  letter-spacing: 0.2rem;
}

.event-container .subline {
  font-size: 1.76rem;
  padding-bottom: 1.43rem;
  text-transform: uppercase;
}

.event-container .timeline {
  display: flex;
  flex-direction: column;
  font-size: 3.53rem;
  margin-left: -20px;
  margin-top: 2rem;
  min-height: 50px;
  position: relative;
}

.event-container .timeline .description {
  font-size: 1.76rem;
  padding: 0.25rem 0;
  max-width: 550px;
}

.event-container .timeline .heading {
  margin: 0;
  padding: 0;
}

.event-container .timeline .item {
  padding: 0.8rem 1rem;
}

.event-container .timeline .sub-heading {
  font-size: 1.76rem;
  margin: 0;
  padding: 0.25rem 0;
}

.event-container .with-right-border {
  border-right: 2px solid #767677;
  min-width: 2px;
}

.event-name-block {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 4.56rem;
}

.event-name-block .sub-heading {
  padding: 0;
  margin: 0;

  font-size: 2rem;
  line-height: 135%;
  letter-spacing: 0.125rem;
  letter-spacing: 3px;
}

.event-name-block .sub-heading.thin {
  font-weight: 400;
  margin-top: 3rem;
}

.event-name-block .sub-heading span {
  background-color: white;
  padding: 0.8rem 1.71rem;
}

.event-container .row.event-pic-small {
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;
}
.event-container .row.event-pic-small img {
  width: 500px;
  height: auto;
}

@media (orientation: portrait) {
  .event-container .row.event-pic-small {
    display: none;
  }

  .event-container .qr-code-wrapper canvas {
    width: 381px !important;
    height: auto !important;
  }

  .event-container .details-container {
    padding-top: 8.475vw;
  }

  .event-name-block .sub-heading {
    font-size: 2.56rem;
  }

  .event-container .heading {
    word-break: unset;
  }
}
