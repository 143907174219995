.delivery-options {
  display: flex;
  flex-wrap: wrap;
  width: 102.5%;
  /* justify-content: space-between; */
}

.delivery-options .gl-icon {
  height: 2.5rem;
  width: 2.5rem;
}

.delivery-options .item {
  background: #fff;
  display: flex;
  height: 11.875rem;
  margin-bottom: 1.25rem;
  min-width: 25rem;
  width: 31.5%;
  margin-right: 1.25rem;
}

.delivery-options .item .highlight-vertical-bar {
  background: #fff;
  margin: 0.125rem;
  padding: 0.25rem;
}

.delivery-options .item.selected .highlight-vertical-bar {
  background: #000;
  margin: 0.125rem;
  padding: 0.25rem;
}

.cursor-pointer {
  cursor: pointer;
}

.debug-text {
  word-break: break-all;
  height: 75px;
  overflow: scroll;
}