.pdp-color-menu-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.pdp-color-menu-container .content {
  display: inline-flex;
  overflow: scroll;
  padding: 1rem 0;
}

.pdp-color-menu-container .title {
  align-items: center;
  display: flex;
  font-size: 0.88rem;
  justify-content: center;
  margin-right: 1rem;
  min-width: 6.25rem;
  text-align: end;
  width: 6.25rem;
  word-break: normal;
  padding-top: 1rem;
}

.pdp-color-menu-container .content div {
  margin-right: 0.75rem;
  margin-top: 0.2rem;
}

.pdp-color-menu-container .selected {
  border-bottom: 0.18rem solid #000;
}

.pdp-color-menu-container::-webkit-scrollbar {
  display: none;
}

.pdp-color-menu-container .image {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0.25rem;
  position: relative;
}

.pdp-color-menu-container .image img {
  height: 3.13vw;
  position: relative;
  width: 3.13vw;
}

.pdp-color-menu-container .image .circle-tick {
  height: 1.5rem;
  position: relative;
  position: absolute;
  right: -15%;
  top: -20%;
  width: 1.5rem;
}

.pdp-color-menu-container div {
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
}

@media (orientation: portrait) {
  .pdp-color-menu-container {
    width: 70vw;
  }
  .pdp-color-menu-container .image img {
    height: 4.69vw;
    width: 4.69vw;
  }

  .pdp-color-menu-container .content {
    padding: 1.5rem 1.5rem;
  }
}
