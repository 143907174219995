.empty-bag p {
  font-size: 1.32rem;
  line-height: 1.8rem;
  padding-top: 1rem;
}

.shopping-bag .empty-bag {
  display: flex;
  height: 45rem;
  padding-top: 20.5rem;
}

.empty-bag .cart-icon {
  padding-bottom: 1.5rem;
}

.empty-bag .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: -10rem;
  padding-bottom: 0;
  padding-left: 0.5rem;
}

.empty-bag__btn-container {
  padding: 2rem;
}

/* 4k vertical adjustments*/
@media (orientation: portrait) {
  .shopping-bag .empty-bag {
    height: 30rem;
  }
}
