html {
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

p {
  font-size: 1.125rem;
}

::-webkit-scrollbar {
  display: unset;
  display: none;
  width: 0.2vw;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0.2vw rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
  display: none;
}

body div::-webkit-scrollbar {
  display: none;
}

.main-container {
  font-size: 0.8rem;
  margin-top: 0;
  width: 96vw;
}

.main-container-full-screen {
  font-size: 0.8rem;
  margin-top: 0;
  width: 100vw;
}

@media only screen and (min-width: 2160px) {
  .main-container {
    font-size: 1.8rem;
  }
}

.left-arrow {
  background: #000;
  bottom: 20.8%;
  left: 75%;
  position: absolute;
  right: -28.12%;
  top: 23.93%;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.background-loader {
  background-image: url("./assets/gifs/loader.gif");
  background-repeat: no-repeat;
  background-size: 3.125rem;
  background-position: center;
}

@media (min-width: 2160px) {
  .gl-heading--s {
    font-size: 1.2rem;
  }

  .gl-label--regular {
    font-size: 1.2rem;
  }

  .plp__filters-applied {
    font-size: 1.2rem;
  }

  .sidebar-wrapper {
    padding-top: 1.2rem;
    width: 3vw;
  }

  .sidebar-wrapper img {
    margin-bottom: 1.2rem;
    width: 1.5rem;
  }

  .gl-product-card__name,
  .gl-product-card__time {
    font-size: 1rem;
  }

  .gl-product-card__price {
    font-size: 1rem;
  }

  .gl-product-card__category {
    font-size: 1rem;
  }

  .gl-product-card__color {
    font-size: 1.2rem;
  }

  .filter-panel {
    font-size: 1.5rem;
  }

  .filter-panel .option-list p {
    font-size: 1.5rem;
    line-height: 120%;
    padding: 0.5rem;
  }

  .gl-checkbox__icon {
    height: 2rem;
    width: 2rem !important;
  }

  .gl-checkbox {
    margin-right: 0.5rem;
  }

  .gl-product-card__name,
  .gl-product-card__time {
    font-size: 1.2rem;
    line-height: 183%;
  }

  .gl-badge {
    font-size: 1rem;
  }
}

@media (min-width: 3840px) {
  .gl-heading--s {
    font-size: 2rem;
  }

  .gl-label--regular {
    font-size: 1.5rem;
  }

  .plp__filters-applied {
    font-size: 1.5rem;
  }

  .sidebar-wrapper {
    padding-top: 1.5rem;
    width: 4.2vw;
  }

  .sidebar-wrapper img {
    margin-bottom: 2rem;
    width: 3rem;
  }

  .gl-product-card__price {
    font-size: 1.2rem !important;
  }

  .gl-price-item--small {
    font-size: 1.2rem !important;
  }

  .gl-product-card__category {
    font-size: 1.2rem;
  }

  .gl-product-card__color {
    font-size: 1.2rem;
  }

  .image_placeholder {
    padding-bottom: 100%;
  }

  .category_placeholder {
    margin-top: 1rem;
  }

  .name_placeholder {
    margin-top: 1.5rem;
  }

  .price_placeholder {
    margin-top: 0.5rem;
  }
}

.hidden {
  display: none;
}

.animate-top-left {
  animation: animatetopleft 0.4s;
  position: relative;
}

.animate-right {
  animation: animateright 0.4s;
  position: relative;
}

.animate-bottom {
  animation: animatebottom 0.4s;
  position: relative;
}

@keyframes animateright {
  0% {
    opacity: 0;
    right: -100vw;
  }

  100% {
    opacity: 1;
    right: 0;
  }
}

@keyframes animatetopleft {
  0% {
    left: -10vw;
    opacity: 0;
    top: -10vh;
  }

  100% {
    left: 0;
    opacity: 1;
    top: 0;
  }
}

@keyframes animatebottom {
  0% {
    bottom: -100vw;
    opacity: 0;
  }

  100% {
    bottom: 0;
    opacity: 1;
  }
}

.gl-price-item--sale {
  font-weight: 700;
}

.gl-cta--secondary-light {
  height: 4rem;
  width: 100%;
}

.gl-cta--primary-light {
  height: 4rem;
  width: 100%;
}

.gl-cta--secondary {
  height: 4rem;
  width: 100%;
}

.gl-cta__content {
  font-size: 1rem;
}

.sse-debug {
  color: green;
  position: fixed;
  opacity: 0.3;
  z-index: 100;
  padding: 0 0.2rem;
  bottom: 50px;
  /* right: 10rem; */
}

.timeout-counter {
  background: #d0d0d0;
  width: 20rem;
  position: fixed;
  bottom: 5rem;
  left: 0px;
  z-index: 100;
  opacity: 0.6;
  padding: 1rem;
  font-size: 1rem;
}

.no-scroll {
  overflow: hidden;
}
