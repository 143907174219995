/* Overrides - new font 2024 related */
@font-face {
  font-family: "ITCFranklinGothic-BookCompressed";
  src: url("./fonts/ITCFranklinGothicLTBookCompressed_normal_condensed.woff2")
    format("truetype");
}

@font-face {
  font-family: "ITCFranklinGothic-DemiCompressed";
  src: url("./fonts/ITCFranklinGothicLTDemiCompressed_normal_condensed.woff2")
    format("truetype");
}

@font-face {
  font-family: "Denton-Light";
  src: url("./fonts/Denton-Light.woff2") format("truetype");
}

.tap-to-get-started-title h1 {
  font-family: "ITCFranklinGothic-DemiCompressed";
}

.site-header .label,
.filter-panel .gl-label,
.filter-panel .option-list,
.filter-panel .filter-item-row,
.plp__filters-applied,
.gl-product-card__details .gl-label,
.gl-product-card__details .gl-product-card__category,
.product-card .gl-product-card__color,
.plp-container .go-to-top-content,
.pdp-color-menu-container div,
.gl-tab__item,
.product-details,
.review-section h4,
.product-side-panel-container .pre-header__text,
.product-side-panel-container .heading,
.product-side-panel-container .gl-price-item,
.gl-callout--urgent .gl-callout__wrapper,
.details-wrapper.product-sidepanel p,
.add-to-cart-button,
.gl-cta__content,
.store-availability_container,
.store-availability .gl-input .gl-input__field,
.product-sidepanel,
.empty-bitm p,
.empty-help p,
.gl-cta--primary,
.search-result-container,
.search-input,
.search-input .gl-input input,
.timeout-confirmation__content .body p,
.membership-banner .description-section .title,
.membership-banner .description-section .body,
.membership-banner .description-section .feature-list div,
.plp-heading .item.result-count,
.product-card .gl-price-item--small,
.cart .product-details h4,
.gl-price,
.cta-items .tax-label,
.event-container .heading,
.gl-app,
.full-width .heading h5 {
  font-family: "ITCFranklinGothic-BookCompressed" !important;
}

.landing-page-wrapper h2.heading,
.landing-page-wrapper .label,
.plp-navigation .gl-heading.gl-heading--m.gl-heading--no-margin,
.gl-badge--urgent,
.description .text-content h5,
.product-sidepanel h4,
.highlights__POIs h5 p,
.event-container .sub-heading,
.events .heading,
h4 {
  font-family: "ITCFranklinGothic-DemiCompressed" !important;
}

.product-sidepanel h4 {
  font-weight: unset;
}

h5.gl-heading--italic {
  font-family: "ITCFranklinGothic-BookCompressed" !important;
  font-weight: unset;
}

.product-details .description h5.gl-heading--italic {
  font-family: "ITCFranklinGothic-BookCompressed" !important;
}

.gl-heading--italic {
  font-style: italic;
}

/* Overrides - new font 2024 related - end*/
