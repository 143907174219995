.search-view-container {
  /* background: red; */
  width: 75vw;
  height: 100vh;
  padding-left: 25vw;
  overflow: scroll;
  width: 100vw;
}

.search-view {
  padding-top: 3rem;
  background: #fff;
  padding-right: 10rem;
  padding-left: 3rem;
  height: 120vh;
  overflow: scroll;
}

.search-input {
  border-bottom: 1px solid #d0d0d0;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 1rem;
}

.search-input .icon {
  width: 1.5rem;
}

.search-input .input {
  font-size: 1.125rem;
  line-height: 1.125rem;
}

.search-input .gl-input input {
  border: unset;
  width: 50vw;
}

.search-input .left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.search-input .right p {
  padding: 0;
  margin: 0;
  text-decoration: underline;
}

.search-view .placeholder {
  margin-top: 4rem;
}

.suggestions-list {
  text-transform: uppercase;
  width: 30%;
}

.suggestions-list li {
  margin-top: 1rem;
  font-size: 1.125rem;
}

.product-suggestion-grid-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.search-result-container {
  display: flex;
  padding-top: 2rem;
}

.see-all-for-search-text {
  margin-top: 5rem;
  font-size: 1.125rem;
  text-decoration: underline;
  line-height: 1.5rem;
}

.no-result-info-container {
  padding-top: 2rem;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.no-result-info-container img {
  padding-bottom: 2rem;
  width: 3.75rem;
}

.btn-start-new-search {
  margin-top: 6rem;
  width: 20rem;
}

.search-result-container-skeleton {
  display: flex;
  margin-top: 3rem;
}

.suggestion-list-item-skeleton {
  background-color: #ebedee;
  width: 15rem;
  height: 2rem;
  opacity: 0.4;
}

.suggestion-list-item-skeleton.small {
  width: 6rem;
}

.suggestion-list-item-skeleton.medium {
  width: 12rem;
}

.suggestion-product-skeleton {
  background-color: #ebedee;
  width: 18rem;
  height: 18rem;
  opacity: 0.4;
  padding: 0 !important;
  margin: 0 !important;
}

.product-grid-skeleton-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.product-grid-skeleton-wrapper div {
  margin-left: 0.5rem;
  margin-top: 1rem;
}
