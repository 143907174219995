.timeout-confirmation__content {
  background: #fff;
  min-height: 20rem;
  width: 45vw;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.timeout-confirmation__content .body {
  margin-top: 2rem;
}

.timeout-confirmation__content .body p {
  font-size: 1.3125rem;
}

.timeout-confirmation__content .gl-cta {
  width: 100%;
  margin-top: 1rem;
}

.timeout-confirmation__content .gl-cta--secondary-light {
  height: 4rem;
  width: 100%;
}

.timeout-confirmation__content .title {
  font-weight: bold;
  font-size: 1.25rem;
  text-transform: uppercase;
  width: 90%;
}

.timeout-confirmation__content .button-container {
  display: flex;
  justify-content: space-between;
}

.timeout-confirmation__content .button-container div {
  width: 45%;
}

/* 4k vertical adjustments*/
/* @media (orientation: portrait) {

} */
