.gallery-wrapper {
  position: relative;
}
.pdp-gallery-thumnail-container {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0.75rem;
  width: 100%;
}
.pdp-gallery-thumnail-container .content {
  display: inline-flex;
  overflow: scroll;
}

.pdp-gallery-thumnail-container .title {
  background: yellow;
  justify-self: flex-start;
}

.pdp-gallery-thumnail-container .content div {
  margin-right: 0.625rem;
  position: relative;
  /*    width: 100px;;*/
}

.pdp-gallery-thumnail-container .content .img img {
  /*    width: 1.5rem;*/
  width: 1.25vw;
  border: 1px solid #000;
  border-bottom: 1px solid #000;
  position: relative;
}

.pdp-gallery-thumnail-container .content .img .highlighter {
  padding: 0.09rem;
  background: #000;
  width: 1.2vw;
  position: absolute;
  bottom: 3px;
  left: 0px;
}

.pdp-gallery-thumnail-container .content .selected {
  padding: 0.1rem;
  width: 100%;
  background: red;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.pdp-gallery-thumnail-container::-webkit-scrollbar {
  display: none;
}

/* 4k vertical adjustments*/
@media (orientation: portrait) {
  .pdp-gallery-thumnail-container .content .img img {
    width: 2.35vw;
  }

  .pdp-gallery-thumnail-container .content .img .highlighter {
    width: 2.35vw;
  }

  .pdp-gallery-thumnail-container .content .img .highlighter {
    bottom: 8px;
  }
}
