.bitm-confirmation_container {
  display: flex;
}

.bitm-confirmation__content_left {
  width: 25vw;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid #d3d7da;
}

.bitm-confirmation__content_left .qr_code {
  margin-top: 2.5rem;
}

.bitm-confirmation__content_left .instruction {
  margin-top: 2rem;
}

.bitm-confirmation__content_right {
  background: #fff;
  min-height: 20rem;
  width: 25vw;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bitm-confirmation__content_right .body {
  margin-top: 2rem;
}

.bitm-confirmation__content_right .gl-cta {
  width: 100%;
  margin-top: 1rem;
}

.bitm-confirmation__content_right .gl-cta--secondary-light {
  height: 4rem;
  width: 100%;
}

.bitm-confirmation__content_left__wide {
  width: 35vw;
}

.bitm-confirmation_container .debug_qr_url {
  color: blue;
  word-break: break-all;
}

.bitm-confirmation_container .loader-wrapper {
  width: 4rem;
  height: 4rem;
  margin-left: 43%;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bitm-confirmation_container .loader-wrapper .gl-loader {
  height: 4rem;
  width: 4rem;
}
