body {
  font-size: 16px;
}

.membership-banner {
  background: #d0d0d0;
  background-color: #fff;
  background-image: url("../../assets/images/texturebackground.jpg");
  background-position: center;
  background-repeat: repeat-y;
  min-height: 100vh;
}

.adiculb-logo {
  height: auto;
  display: flex;
  justify-content: center;
  padding-top: 7.75vh;
  height: 200px;
}

.hero-image {
  display: flex;
  justify-content: center;
  margin-top: 5rem;
}

.hero-image img {
  max-width: 100vw;
}

.footer-image {
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin-top: 6.56rem;
}
.footer-image img {
  width: 50vw;
}

.description-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 9rem;
}

.description-section .title {
  width: 50vw;
  text-align: center;

  font-family: "AdihausDIN";
  font-style: italic;
  font-weight: 500;
  font-size: 5.31rem;
  /* or 100% */

  text-align: center;
  text-transform: uppercase;
}

.description-section .body {
  margin-top: 5.75rem;
  font-size: 4rem;
  width: 45vw;
  text-align: center;
  font-weight: 400;
  margin-bottom: 4.43rem;
}

.description-section .feature-list {
  font-size: 2.5rem;
  text-transform: uppercase;
  display: flex;
  flex-wrap: wrap;
  width: 70vw;
  text-align: center;
  justify-content: center;
  margin-top: 0.5rem;
  gap: 0.5rem;

  font-weight: 700;
  /* or 133% */

  letter-spacing: 0.125rem;
  text-transform: uppercase;
}

.description-section .feature-list div {
  background-color: white;
  font-weight: 700;
  padding: 0.75rem 1.56rem;
}

.qr-container {
  margin-top: 3.25rem;
  font-size: 3.125rem;
  display: flex;
  justify-content: center;
}

.qr-container .qr-img {
  position: relative;
  padding-top: 4.37rem;
  padding-left: 4rem;
}

.top-left {
  width: 3.125rem;
  height: 3.125rem;
  position: absolute;
  top: 0;
  left: 0;
}

.bottom-right {
  width: 3.125rem;
  height: 3.125rem;
  position: absolute;
  bottom: 0;
  right: 0;
}

@media (orientation: portrait) {
  .adiculb-logo {
    height: 450px;
  }
}
