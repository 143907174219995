@media (orientation: landscape) {
  .map-based-selection-wrapper {
    padding-bottom: 230px;
  }
}

@media (orientation: landscape) {
  .user-details-wrapper {
    padding-bottom: 230px;
  }
}
