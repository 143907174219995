.store-availability_container {
  display: flex;
  /* justify-content: left; */
  width: 85vw;
  height: 90vh;
}

.store-info-view {
  /* border: 4px dotted red; */
  padding-top: 2rem;
  padding-left: 2rem;
  overflow: scroll;
  width: 74%;
}

.availability-info-view {
  width: 26%;
  padding-top: 5.5rem;
  padding-left: 2rem;
}

.size-grid {
  width: 100%;
}

.debug-ssm-selection {
  width: 30%;
}

.debug-ssm-selection li {
  text-transform: uppercase;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  text-decoration: underline;
}

/* 4k vertical adjustments*/
@media (orientation: portrait) {
  .store-availability_container {
    width: 85vw;
    height: 40vw;
  }
}

.store-availability-loader-wrapper {
  display: flex;
  align-items: center;
  margin: 0 auto;
}
