.checkout {
  background: #eceff1;
  min-height: 100vh;
  /* padding: 0 2rem; */
  padding-top: 1.92rem;
}

.checkout .back-button {
  background: #eceff1;
  padding-left: 2.4rem;
  padding-top: 31.6px;
}

.checkout .bold {
  font-weight: 700;
}

.checkout .gl-cta {
  font-size: 1.125rem;
  padding: 2.2rem;
  width: 100%;
}

.checkout .gl-cta--disabled {
  background: #c8cbcc;
  border: 1px solid #c8cbcc;
}

.checkout .gl-cta--tertiary {
  font-size: 1rem;
  letter-spacing: 0.125rem;
  padding-top: 2rem;
}

.checkout .gl-input .gl-input__field {
  border: none;
  border-bottom: 1px solid #eceff1;
  padding: 2rem;
}

.checkout .gl-input__label {
  font-size: 1.125rem;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
}

.checkout .heading {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.875rem;
  margin-bottom: 0.5rem;
}

.checkout .input-container {
  /* border: 2px dotted blue; */
  max-width: 72vw;
}

.checkout .navigation {
  display: flex;
  justify-content: center;
  padding: 0 2.5rem 2rem;
  width: 1rem;
}

.checkout .right .gl-icon {
  width: 0.5rem;
}

.checkout .tertiary-btn .gl-cta {
  padding-left: 0;
  text-align: left;
}

.consumer-details-form {
  background: #fff;
  margin-top: 1rem;
  padding: 2rem;
}

.consumer-details-form .item {
  min-width: 45%;
}

.consumer-details-form .row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
}

.item .center {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.25rem 0.5rem;
  width: 74.4%;
}

.item .left {
  align-items: flex-start;
  display: flex;
  justify-content: center;
  width: 17.7%;
}

.item .left div {
  margin-top: 0.8rem;
}

.item .right {
  align-items: center;
  display: flex;
  width: 7.9%;
}

.order-summary {
  background: #fff;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  width: 24%;
  overflow: scroll;
}

.order-summary .content {
  /* min-height: 25vw; */
  padding: 2rem;
  padding-top: 1rem;
}

.order-summary .footer {
  border-top: 1px solid #eceff1;
  margin-top: 2rem;
  padding: 2rem;
}

.order-summary .footer .gl-icon {
  height: 1rem;
  width: 1rem;
}

.order-summary .header {
  align-items: center;
  border-bottom: 1px solid #eceff1;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 2rem;
}

.order-summary .header h4 {
  margin: 0;
  padding: 0;
}

.order-summary .light p {
  color: #9a9b9b;
  font-size: 1.125rem !important;
}

.order-summary .promo-code,
.order-summary .employee-id {
  border-bottom: 1px solid #eceff1;
  color: #9a9b9b;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

.order-summary .promo-code div,
.order-summary .employee-id div {
  padding: 2rem 1rem;
}

.order-summary .row {
  display: flex;
  justify-content: space-between;
  padding: 0.78rem 0;
}

.order-summary p {
  font-size: 1.3125rem !important;
  padding: 0.1rem 0;
}

.selected-location-info {
  background: #fff;
  margin-top: 1rem;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.selected-location-info > div {
  max-width: 30%;
}

.selected-location-info .delivery-date {
  color: #54b75e;
  font-weight: bold;
}

.selected-location-info p {
  font-size: 1.32rem;
  line-height: 143%;
}

.section-clear {
  padding-bottom: 1.5rem;
}

.user-details-wrapper .gl-input__field {
  font-size: 1.125rem;
}

.loading-animation-container {
  position: absolute;
  top: 46%;
  left: 46%;
}

.helper-ctas {
  border: 3px dotted orange;
  background: yellow;
  padding: 1rem;
  width: 20%;
}

.debug-info {
  padding: 1rem;
  background: cyan;
  border: 5px dotted blue;
  width: 77.5%;
  word-break: break-all;
  overflow: scroll;
  /* height: 20rem; */
  margin-bottom: 0.5rem;
}

.gl-input__field {
  font-size: 1.125rem;
}

@media only screen and (max-width: 1080px) {
  .consumer-details-form .row {
    flex-direction: column;
  }
}

.checkout-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 20vw;
  height: 15vw;
}
.checkout-loading > div {
  padding-bottom: 1.5rem;
}

.start-checkout-error {
  width: 15vw;
  text-align: center;
  color: red;
  margin: auto;
}

.start-checkout-error > button {
  width: 15vw;
  text-align: center;
}

.cart-image-gallery {
  display: none;
}

.checkout-form-container {
  padding: 0 2rem;
}

/* 4k vertical adjustments*/
@media (orientation: portrait) {
  .cart-image-gallery {
    display: block;
    height: 1215px;
  }

  .order-summary {
    height: 2560px;
    top: 1240px;
  }

  .checkout .navigation {
    padding: 2rem 2.5rem;
    margin-bottom: 30px;
  }
}

.checkout .checkout-form-container h4 {
  display: flex;
  justify-content: space-between;
  padding-right: 25vw;
}

.checkout .checkout-form-container button {
  width: auto !important;
  /* padding: 0 !important; */
  padding: 1rem;
}
