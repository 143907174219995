.product-card {
  margin-bottom: 1rem;
  width: 15.5vw;
}

.product-card span {
  font-size: 1rem;
}

.gl-product-card__media .gl-product-card__media--base img {
  min-height: 14.9vw;
}

.product-card .gl-product-card__color {
  font-size: 1rem;
  /* text-transform: capitalize; */
  text-transform: unset;
}

.product-card .gl-price-item--small {
  font-size: 1rem;
}

.product-card .gl-product-card__details-main {
  height: unset;
}

.gl-product-card__name,
.gl-product-card__time {
  max-height: unset !important;
}
@media only screen and (max-width: 1080px) {
  .product-card {
    width: 19.2vw;
  }
}

@media (min-width: 960px) {
  .gl-product-card-container {
    margin: 0;
  }

  .gl-product-card {
    border: 0.24rem solid transparent;
  }
}
