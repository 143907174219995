.bitm-status-container {
  background: #eceff1;
  height: 11rem;
}

.bitm-status .header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.bitm-status .bitm-progress-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bitm-status h4 {
  font-family: AdihausDIN;
  font-size: 18px;
  font-size: 1.125rem;
  letter-spacing: 2px;
  line-height: 133%;
  margin-bottom: 0;
  text-transform: uppercase;
}

.bitm-status .bitm-progress-bar .black-circle {
  padding: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d0d0d0;
  border-radius: 2rem;
  background: #000;
}

.bitm-status .bitm-progress-bar > div {
  padding: 1.8rem;
}

.bitm-status .bitm-progress-bar .black-circle > div {
  background: #eceff1 !important;
  padding: 0.25rem;
  border-radius: 2rem;
}

.bitm-status .circle {
  padding: 0.5rem !important;
  border-radius: 2rem;
  border: 0.07rem solid #808080;
}

.bitm-status .separator {
  background: #9a9b9b !important;
  padding: 0.02rem !important;
  width: 35%;
}

.bitm-status .separator.highlighted {
  background: #000 !important;
}

/* bitm status end */
