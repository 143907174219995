.interaction-view-container {
  width: 100vw;
  height: 100vh;
}

.container-video div {
  width: 100% !important;
  height: 100% !important;
}

.caption-overlay {
  color: white;
  font-size: 300px;
  text-transform: uppercase;
  width: 1058px;
  display: flex;
  justify-content: center;
}

.interaction-video-component-wrapper {
  background-color: black;
  width: 100vw;
  height: 100vh;
}
.interaction-video-component-wrapper .video-component-wrapper {
  position: relative;
}

.interaction-video-component-wrapper .container-video {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.interaction-video-component-wrapper .container-video video {
  height: 101interaction-video-component-wrapper 0.5vh;
  object-fit: cover;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.interaction-video-component-wrapper .video-debug-info {
  position: absolute;
  bottom: 0px;
  padding: 1rem;
  opacity: 0interaction-video-component-wrapper 0.5;
  animation: blinkingText 5s infinite;
}

.interaction-video-component-wrapper .video-debug-info p {
  margin: 0;
  font-size: 1.5rem;
  padding: 0;
}

video {
  max-width: 100%;
  height: auto;
}

@keyframes blinkingText {
  0% {
    background: black;
    color: yellowgreen;
  }
  80% {
    background: black;
    color: yellowgreen;
  }
  90% {
    background: transparent;
    color: transparent;
  }
  99% {
    background: transparent;
    color: transparent;
  }
  100% {
    background: black;
    color: yellowgreen;
  }
}

.fullscreen-overlay-container {
  background: #000;
  width: 100vw;
  height: 100vh;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.51;
  z-index: 100 !important;
  position: absolute;
  top: 0;
  left: 0;
}

.fullscreen-overlay-container .caption {
  font-size: 4rem;
  width: 60vw;
  line-height: 100%;
  text-align: center;
  text-transform: uppercase;
}

.fullscreen-overlay-container img {
  margin-top: 200px;
  margin-bottom: 200px;
  width: 238px;
}

/* 4k vertical adjustments*/
@media (orientation: portrait) {
  .interaction-video-component-wrapper .video-debug-info {
    position: absolute;
  }

  .interaction-video-component-wrapper .video-debug-info p {
    font-size: 1interaction-video-component-wrapper 0.5rem;
    line-height: 120%;
  }

  .fullscreen-overlay-container .caption {
    font-size: 12.5rem;
  }

  .fullscreen-overlay-container img {
    margin-top: 344px;
    margin-bottom: 344px;
    width: 238px;
  }
}
