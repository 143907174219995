.languague-options-container {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  opacity: 1;
  font-size: 2rem;
}

.languague-options {
  background: #fff;
  flex-direction: column;
  height: 100vh;
  opacity: 1;
  width: 29.45vw;
  z-index: 100;
  max-height: 100vh;
  overflow: auto;
}

.languague-options .header {
  padding: 2.25rem;
}

.language-list {
  padding: 0 2.25rem;
  display: flex;
  flex-direction: column;
  font-size: 1.3125rem;
  padding-bottom: 8rem;
  text-transform: uppercase;
}

.language-list .language-list__row {
  padding: 1rem 0px;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  border-bottom: 1px solid #767677;
}

.language-list__row__flag {
  width: 3.75rem;
  height: 2rem;
}

.multi-lang-flag {
  width: 3.75rem;
}

.language-list__row.highlighted {
  font-weight: 700;
}
