.cart-gallery-wrapper {
  position: relative;
  width: 98%;
}

.cart-gallery-wrapper img {
  height: 10%;
}

.cart-gallery-wrapper .img-container {
  background: #edeff0;
  height: 36vw;
  padding-right: 0.2rem;
  position: relative;
  width: 70.5vw;
}

.cart-carousel-div {
  display: flex;
  justify-content: center;
  padding: 1vw 18vw;
}

.cart-carousel-div-img {
  width: 20vw;
}

.cart-carousel-div-img img {
  height: 35vw;
  width: 35vw;
}

@media (max-width: 1080px) {
  .cart-carousel-div-img img {
    height: 35vw;
    width: 35vw;
  }

  .cart-carousel-div {
    padding: 1vw 16vw;
  }

  .cart-gallery-wrapper {
    margin-top: -10.5vw;
    width: 93%;
  }
}

.cart-gallery-wrapper .gl-carousel__item-wrapper--highlight:hover,
.cart-gallery-wrapper .gl-carousel__item-wrapper--active {
  border: unset;
}

.cart-gallery-wrapper .gl-pagination {
  display: none;
  margin-top: 1rem;
}

.cart-gallery-wrapper .gl-carousel__prev-next-button--square-type {
  margin: 2.5rem;
}

/* 4k vertical adjustments*/
@media (orientation: portrait) {
  .cart-gallery-wrapper {
    width: unset;
  }

  .cart-gallery-wrapper .img-container {
    height: 1215px;
    width: unset;
  }

  .btn-fullscreen-gallery .gl-icon {
    vertical-align: unset;
  }

  .cart-carousel-div-img img {
    height: 1215px;
    width: 1215px;
    margin-top: -22px;
  }

  .gl-carousel__item-wrapper--highlight:hover,
  .gl-carousel__item-wrapper--active {
    border: none;
  }

  /* .checkout .pdp-gallery-thumnail-container .content .img img {
    width: 6rem !important;
  }

  .checkout .pdp-gallery-thumnail-container .content .img .highlighter {
    width: 4.45vw;
  } */
}
