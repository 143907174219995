.cart-wrapper {
  padding: 0;
}

.cart {
  background: #eceff1;
  display: flex;
  margin-bottom: 0.125rem;
  padding: 0;
  padding-bottom: 1rem;
  padding-top: 1rem;
  width: 100%;
}

.cart .product-details {
  padding: 1rem;
  width: 100%;
}

.cart .image-container {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 30%;
}

.cart img {
  height: 10rem;
  margin-left: 1rem;
  width: 10rem;
}

.cart .product-details .wrapper {
  width: 100%;
}

.cart .product-details .wrapper div {
  font-size: 1.125rem !important;
}

.cart .product-details .title {
  width: 90%;
}

.cart .product-details .sale {
  background: #c53622 !important;
  color: #fff;
}

.cart .product-details {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: unset;
}

.cart .product-details .price {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0.75rem 1rem 1.5rem;
  width: 95%;
}

.cart .product-details .price > div {
  padding: 0.2rem;
}

.cart .action-panel {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0.75rem 1rem;
  width: 95%;
}

.cart .action-panel .left,
.action-panel .right {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.cart .action-panel .left div,
.action-panel .right div {
  margin-left: 0.2rem;
  text-transform: uppercase;
}

.cart .action-panel .left .quantity {
  margin-bottom: 0.05rem;
  padding: 0.3rem;
}

.cart .product-details h4 {
  font-family: AdihausDIN;
  font-size: 18px;
  font-size: 1.125rem;
  letter-spacing: 2px;
  line-height: 133%;
  margin-bottom: 0;
  text-transform: uppercase;
}

.cart .action-panel .gl-cta {
  height: 2.125rem;
  min-height: unset;
  width: 2.125rem;
}

.cart .action-panel .gl-cta .gl-icon {
  height: 50%;
  width: 50%;
}

.cart .action-panel .size-options.dropdown-closed .gl-dropdown-custom__select {
  background: unset;
  border: 1px solid transparent;
}

.cart .action-panel .size-options.dropdown-open .gl-dropdown-custom__select {
  background: unset;
}

.cart .action-panel .size-options .gl-dropdown-custom__options {
  margin-left: unset;
}

.shopping-bag .cta-items {
  padding: 2rem;
}

.cta-items .bold {
  font-size: 1.313rem;
  font-weight: 700;
}

.cta-items {
  display: flex;
  justify-content: space-between;
  width: 99%;
}

.cta-items .left {
  align-items: center;
  display: flex;
}

.cta-items .tax-label {
  font-size: 1.125rem;
  padding-left: 0.5rem;
}

.checkout-cta {
  margin: 0 2rem;
}

.checkout-cta .gl-cta__content {
  font-size: 1rem;
}

.delete-bag .gl-cta--tertiary {
  border-bottom: 1px solid #000;
  padding-top: 2rem;
}

.cart .trash-icon img {
  height: 2.2rem;
  width: 2.2rem;
}

.delete_bag_container {
  display: flex;
  font-size: 1.125rem;
  font-weight: 700;
  letter-spacing: 0.08rem;
  padding: 0.2rem;
  padding-bottom: 2rem;
  padding-top: 2rem;
  text-transform: uppercase;
}

.delete-btn {
  border-bottom: 1px solid #000;
  padding-bottom: 0.2rem;
}

.cart-container {
  display: flex;
  flex-direction: column;
  /* height: 91%; */
  justify-content: space-between;
  overflow: scroll;
  background: #fff;
}

.info-quantity-auto-adjusted {
  color: red;
}

.cart-container .disabled_btn {
  opacity: 0.3;
}
