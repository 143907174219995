.sidebar-wrapper-menu {
  background: #fff;
  border-left: 1px solid #ebedee;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
  width: 5rem;
  z-index: 10 !important;
}

.sidebar-wrapper-menu .img-bigger {
  margin-left: -0.7rem;
  width: 3rem;
}

.sidebar-wrapper-menu .primary-menu {
  align-content: center;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  justify-self: center;
  z-index: 100;
}

.sidebar-wrapper-menu .primary-menu > div {
  align-self: center;
  padding: 1rem 0.5rem;
  text-align: center;
  width: 3rem;
  width: 100%;
}

.sidebar-wrapper-menu .secondary-menu {
  background: #fff;
  bottom: 0;
  display: flex;
  flex-direction: column-reverse;
  /* margin-bottom: 0.5rem; */
}

.sidebar-wrapper-menu .secondary-menu div {
  align-self: center;
  padding: 1rem 0.5rem;
  text-align: center;
  width: 100%;
}

.sidebar-wrapper-menu img {
  width: 3rem;
}

.sidebar-wrapper-menu .question-mark img {
  width: 1.5rem;
}

.side-panel {
  display: flex;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100 !important;
}

.side-panel .content {
  border-left: 1px solid #000;
  height: 100vh;
  overflow: scroll;
  width: 100vw;
}

.overlay-background {
  background: rgba(0, 0, 0, 0.5);
}

/* 4k vertical adjustments*/
@media (orientation: portrait) {
  .side-panel {
    margin-top: 56.25%;
  }

  .sidebar-wrapper-menu {
    height: 65vh;
  }

  .sidebar-wrapper-menu img {
    width: 5rem;
  }

  .sidebar-wrapper-menu {
    width: 9rem;
  }
}
