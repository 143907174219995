.modal {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 105;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
}

.modal .close-btn {
  position: absolute;
  top: 2rem;
  right: 2rem;
}

.modal .back-btn {
  position: absolute;
  top: 2rem;
  left: 2rem;
}

.modal-left-aligned-content {
  align-items: flex-start;
  justify-content: flex-start;
  width: 100vw;
}

.modal-full-width .modal-content {
  width: 100%;
}

.modal-centered .modal-content {
  width: unset;
}

.modal-body {
  border: 1px solid #fff;
  background: #fff;
  position: relative;
}
