.navigation-wrapper {
  display: flex;
  justify-content: center;
  padding: 1rem;
  padding-top: 0;
}

.product-details {
  padding-top: 2rem;
}

.description-container img {
  width: 30%;
}

.pdp-container {
  display: flex;
}

.product-content {
  position: relative;
  width: 70vw;
}

.product-details {
  margin: 0 auto;
  width: 66.5%;
}

.product-details h5 {
  font-size: 1.6rem;
}

.highlights {
  display: flex;
  justify-content: left;
  width: 100%;
}

.highlights__POIs {
  display: flex;
  flex-direction: column;
  width: 33%;
}

.highlights__POIs .item {
  margin-right: 2rem;
}

.highlights {
  flex-wrap: wrap;
  margin-right: 0;
}

.highlights div {
  margin-bottom: 1rem;
}

.highlights__POIs .item img {
  width: 100%;
}

.description {
  display: flex;
}

.description .text-content {
  background: #fff;
  width: 50%;
  padding-right: 2rem;
}

.description .media {
  width: 50%;
}

.description .media img {
  width: 100%;
}

.bullets {
  display: flex;
  font-size: 1.125rem;
}

.bullets ul {
  width: 50%;
}

.product-details h5 {
  font-size: 1.88rem;
  line-height: 92%;
}

.highlights h5 {
  font-size: 1.25rem;
  line-height: 90%;
}

.product-details section {
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.scrollto-marker {
  /* border: 4px dotted yellowgreen; */
}

/* 4k vertical adjustments*/
@media (orientation: portrait) {
  .product-details {
    margin: unset;
    margin-left: 7.5vw;
    width: 61vw;
    padding-bottom: 40vh;
  }

  .navigation-wrapper {
    justify-content: flex-start;
  }
}
