.new-gallery-wrapper {
  /* margin-top: -1.5rem; */
  position: relative;
  width: 98%;
}

.new-gallery-wrapper img {
  height: 10%;
}

.new-gallery-wrapper .img-container {
  background: #edeff0;
  height: 36vw;
  padding-right: 0.2rem;
  position: relative;
  width: 70.5vw;
}

.my-carousel-div {
  display: flex;
  justify-content: center;
  padding: 1vw 18vw;
}

.my-carousel-div-img {
  min-width: 35vw;
}

.my-carousel-div-img img {
  height: 35vw;
  width: 35vw;
}

@media (max-width: 1080px) {
  .my-carousel-div-img img {
    height: 35vw;
    width: 35vw;
  }

  .my-carousel-div {
    padding: 1vw 16vw;
  }

  .new-gallery-wrapper {
    margin-top: -10.5vw;
    width: 93%;
  }
}

@media (min-width: 3840px) {
  .new-gallery-wrapper {
    margin-top: -4.5vw;
  }
}

.pdp__wrapper .gl-carousel__item-wrapper--highlight:hover,
.pdp__wrapper .gl-carousel__item-wrapper--active {
  border: unset;
}

.pdp__wrapper .gl-pagination {
  display: none;
  margin-top: 1rem;
}

.btn-fullscreen-gallery {
  background: #fff;
  border: 1px solid #000;
  bottom: 2rem;
  position: absolute;
  right: 2.2rem;
  transform: rotate(90deg);
  z-index: 100;
  display: flex;
  padding: 0.8rem;
}

.new-gallery-wrapper .gl-carousel__prev-next-button--square-type {
  margin: 2.5rem;
}

/* 4k vertical adjustments*/
@media (orientation: portrait) {
  .new-gallery-wrapper {
    width: unset;
  }

  .new-gallery-wrapper .img-container {
    height: 1215px;
    width: unset;
  }

  .btn-fullscreen-gallery .gl-icon {
    vertical-align: unset;
  }

  .my-carousel-div-img img {
    height: 1215px;
    width: 1215px;
    margin-top: -22px;
    margin-left: 3vw;
  }

  .my-carousel-div-img {
    width: unset;
  }

  .btn-fullscreen-gallery {
    right: 5rem;
  }

  .new-gallery-wrapper .gl-carousel__prev-next-button--square-type {
    margin: 5rem;
  }

  .my-carousel-div-img {
    min-height: 1215px;
    min-width: 1350px;
  }
}
