.video-component-wrapper {
  position: relative;
}

.container-video {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 31.64vh;
}

.container-video video {
  height: 101.5vh;
  object-fit: contain;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.container-video div {
  width: 100% !important;
  height: 100% !important;
}

.video-debug-info {
  position: absolute;
  bottom: 0px;
  padding: 1rem;
  opacity: 0.5;
  animation: blinkingText 5s infinite;
}

.video-debug-info p {
  margin: 0;
  font-size: 1rem;
  padding: 0;
}

@keyframes blinkingText {
  0% {
    background: black;
    color: yellowgreen;
  }
  80% {
    background: black;
    color: yellowgreen;
  }
  90% {
    background: transparent;
    color: transparent;
  }
  99% {
    background: transparent;
    color: transparent;
  }
  100% {
    background: black;
    color: yellowgreen;
  }
}

/* 4k vertical adjustments*/
@media (orientation: portrait) {
  .video-debug-info {
    position: absolute;
    bottom: 5rem;
    /* height: 200px; */
  }

  .video-debug-info p {
    font-size: 1.5rem;
    line-height: 120%;
  }
}
