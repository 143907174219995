.plp-container {
  margin: auto;
  padding-bottom: 70vh;
  margin: 0 5rem;
  padding: 0 8rem;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
}

.plp-container .go-to-top-container {
  bottom: 2rem;
  padding: 1rem;
  position: fixed;
  right: 6vw;
  z-index: 1;
}

.plp-container .go-to-top-content {
  align-items: center;
  display: flex;
}

.plp-container .go-to-top-content img {
  height: 1rem;
  margin-left: 1rem;
  width: 1rem;
}

.plp-video-container {
  display: none;
}

.plp-error-message-container {
  background-color: #fff;
  display: flex;
  justify-content: center;
  height: 90vh;
  align-items: center;
}

.plp-error-message-container .gl-callout--urgent {
  width: 40rem;
}

/* Tab for data source type selector */
.plp-data-sourcetype-selector {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}
/* Tab for data source type selector --end*/

.plp-data-sourcetype-selector .gl-tab {
  border: none;
}

/* Overrides - new font 2024 related */
.plp__filters-applied .gl-icon {
  height: 1rem;
  margin-top: -0.25rem;
  width: 1rem;
}
/* Overrides - new font 2024 related - end*/

/* 4k vertical adjustments*/
@media (orientation: portrait) {
  .plp-video-container {
    display: block;
    height: 31.6vh;
    width: 100vw;
    background: #d0d0d0;
  }

  .plp-container .go-to-top-container {
    bottom: 10rem;
  }

  .plp-error-message-container {
    height: 30vh;
  }

  /* Overrides - new font 2024 related */
  .plp-container .filter > div:first-child {
    padding-top: 3px;
  }

  .plp__filters-applied .gl-icon {
    height: 1.4rem;
    padding-top: 0.3rem;
    width: 1.4rem;
    margin-top: 0rem;
  }

  .plp__filters-applied .filter .icon {
    margin-top: -3px;
  }
  /* Overrides - new font 2024 related - end */
}
