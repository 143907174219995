.filter-panel {
  box-shadow: #ebedee 0 -0.09rem 0 inset, #ebedee 0 0.09rem 0 inset;
  display: flex;
  justify-content: space-between;
  margin-left: 0.24rem;
  margin-right: 0.45rem;
  min-height: 4.375rem;
}

.filter-panel .gl-label--regular {
  font-size: 1rem;
}

.filter-options {
  display: flex;
  flex-wrap: wrap;
  max-width: 48vw;
  padding: 0.5rem 0;
}

.filter-panel .option {
  border: 1px solid #fff;
  padding-bottom: 0.2rem;
  padding-top: 0.4rem;
  position: relative;
}

.filter-panel .title .highlighted {
  font-weight: 700;
}

.filter-panel .gl-label {
  cursor: pointer !important;
  line-height: 100%;
  vertical-align: -webkit-baseline-middle;
}

.filter-panel {
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  border-top: 1px solid #fff;
}

.filter-panel .selected {
  border-bottom: 1px solid #000;
  border-bottom: none;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
  border-top: 1px solid #000;
}

.filter-panel .single-value-filter.selected {
  border-bottom: 1px solid #000;
}

.filter-panel .connector {
  background: #fff;
  left: 0;
  margin-top: -2px;
  min-height: 5px;
  min-width: 100%;
  position: absolute;
  z-index: 3;
}

.filter-panel .selected .option-list {
  background: #fff;
  border: 1px solid #000;
  margin-left: -1px;
  margin-top: -1px;
  max-height: 80vh;
  min-width: 20vw;
  overflow: scroll;
  padding-bottom: 1rem;
  padding-right: 2rem;
  position: absolute;
  z-index: 2;
  font-weight: normal;
}

.filter-panel .option-list p {
  font-size: 1rem;
}

.filter-panel .option-list ul {
  list-style-type: none;
  margin-left: 1rem;
  margin-top: 0;
  padding: 0;
}

.filter-panel .option-list li {
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
}

.filter-panel .option-list__hidden {
  display: none;
}

.filter-panel .option .options-container {
  display: none;
}

.filter-panel .selected .options-container {
  display: block;
}

.color-selection .gl-checkbox__icon {
  border: unset;
}

.filter-panel .counter {
  color: #000;
  opacity: 0.35;
  padding-left: 0.4rem;
}

.filter-options .black svg {
  background: #000;
}

.filter-options .blue svg {
  background: blue;
}

.filter-options .white svg {
  background: #fff;
  border: 1px solid #000 !important;
}

.filter-options .white .gl-checkbox--checked .gl-checkbox__icon {
  color: #000 !important;
}

.filter-options .white:before svg {
  color: #000 !important;
}

.filter-options .grey svg {
  background: grey !important;
}

.filter-options .red svg {
  background: red !important;
}

.filter-options .pink svg {
  background: #ffc0cb !important;
}

.filter-options .green svg {
  background: green !important;
}

.filter-options .orange svg {
  background: orange !important;
}

.filter-options .purple svg {
  background: purple !important;
}

.filter-options .beige svg {
  background: #f5f5dc !important;
  border: 1px solid #000 !important;
}

.filter-options .beige:before svg {
  color: #000 !important;
}

.beige .gl-checkbox--checked .gl-checkbox__icon {
  color: #000 !important;
}

.filter-options .yellow svg {
  background: #ff0 !important;
  border: 1px solid #000 !important;
}

.yellow .gl-checkbox--checked .gl-checkbox__icon {
  color: #000 !important;
}

.filter-options .yellow:before svg {
  color: #000 !important;
}

.filter-options .burgundy svg {
  background: #800020 !important;
}

.filter-options .multicolor svg {
  background: linear-gradient(45deg, #f6c, #fff200 50%, #0fc) !important;
}

.multicolor .gl-checkbox--checked .gl-checkbox__icon {
  color: #000 !important;
}

.filter-options .turquoise svg {
  background: #40e0d0 !important;
}

.filter-options .brown svg {
  background: #a52a2a !important;
}

.filter-options .silver svg {
  background: linear-gradient(45deg, #999, #eee 50%, #999) !important;
}

.filter-options .gold svg {
  background: linear-gradient(45deg, #bdb76b, khaki 50%, #bdb76b) !important;
}

.filter-options .filter-panel .secondary-controller {
  display: flex;
  flex-flow: row-reverse wrap;
  max-width: 80vw;
  padding: 0.5rem 1rem;
}

.filter-options .filter-panel .secondary-controller .selected .option-list ul {
  margin-left: 0;
}

.filter-options
  .filter-panel
  .secondary-controller
  .selected
  .option-list
  li.selected {
  background: #ebedee;
  font-weight: 700;
}

.filter-panel .secondary-controller {
  display: flex;
  flex-flow: row-reverse wrap;
  max-width: 80vw;
  padding: 0.5rem 0;
}

.filter-panel .secondary-controller .selected .option-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: -1px;
  padding-bottom: 0;
  padding-right: 0;
  right: 0;
  width: 100%;
  z-index: 2;
}

.filter-panel .secondary-controller .selected .option-list ul {
  margin-left: 0;
}

.filter-panel .secondary-controller .selected .option-list li {
  align-items: center;
  border: none;
  display: flex;
}

.filter-panel .secondary-controller .selected .option-list li div {
  padding-left: 1rem;
}

.filter-panel .secondary-controller .selected .option-list li.selected {
  background: #ebedee;
  font-weight: 700;
}

.filter-panel .secondary-controller .selected .connector {
  background: #fff;
  margin-top: -2px;
  min-height: 4px;
}

.filter-panel .title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  line-height: 100%;
  padding: 0.5rem 1rem;
  min-height: 3rem;
}

.filter-panel .title_text {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.filter-panel .title .gl-icon {
  width: 0.75rem;
}

.filter-panel .icon {
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plp__filters-applied {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 0.24rem;
}

.plp__filters-applied .filter {
  background: #f5f6f6;
  border: 1px solid #ebedee;
  display: flex;
  margin-bottom: 0.5rem;
  margin-right: 1rem;
  padding: 0.3rem 0.5rem;
}

.plp__filters-applied .clear-all {
  border: unset !important;
  text-decoration: underline;
}

.plp__filters-applied .filter .icon {
  padding-left: 0.8rem;
}

.plp__filters-applied .gl-icon {
  height: 0.9rem;
  padding-top: 0.2rem;
  width: 0.9rem;
}

.filter-item-row {
  display: flex;
  font-size: 1rem;
  line-height: 184%;
}

.filter-item-row .gl-checkbox__input {
  margin-right: 1.2rem;
  width: unset;
}

.filter-panel .option-list,
.filter-panel .filter-item-row {
  font-size: 1.125rem;
  line-height: 200%;
}

.filter-panel .gl-checkbox__icon {
  height: 2rem !important;
  width: 2rem !important;
}

.filter-panel .gl-checkbox {
  margin-right: 0.5rem;
}

/* 4k vertical adjustments*/
@media (orientation: portrait) {
  .filter-options {
    max-width: 56vw;
  }

  .filter-panel .gl-label--regular {
    font-size: 1rem;
  }

  .filter-panel .filter-item-row {
    font-size: 1.25rem;
    line-height: 200%;
  }
}
