.home-view {
  max-height: 100vh;
  width: 100vw;
  overflow-y: scroll;
}

.home-scrollable-container {
  max-height: 88vh;
  overflow-y: scroll;
}

@media (orientation: portrait) {
  .home-scrollable-container {
    max-height: 65vh;
  }
}

.dark-background-overlay {
  opacity: 0.8;
}
