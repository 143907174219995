.locations-container {
  padding: 2rem;
  font-size: 1.3125rem;

  height: 85%;
  overflow: scroll;
}

.locations-container__item {
  border-bottom: 1px solid #eceff1;
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}

.locations-container p {
  font-size: 1.3125rem;

  padding: 0.25rem 0;
}

.locations-container .store-name {
  font-weight: bold;
  width: 80%;
  line-height: 128%;
}

.locations-container .estimated-delivery {
  color: #54b75e;
}

.locations-container .opening-hours {
}

.locations-container .location-item-details {
  display: flex;
  justify-content: space-between;
}

.locations-container .location-item-details__left {
  width: 70%;
}

.location-item-details__right {
  display: flex;
  flex-direction: row;
  /* border: 1px dotted blue; */
  align-items: flex-end;
  /* margin-bottom: 2rem; */
  /* height: 5rem; */
}
.location-item-details__right div {
  padding: 0.2rem 0.1rem;
}

.location-item-details__right span {
  color: #9a9b9b;
  font-weight: bold;
}

.locations-container .opening-hours-list-container .item {
  display: flex;
}

.locations-container .opening-hours-list-container .item > div {
  padding: 0 1rem 1rem 0;
}

.opening-hours-list-container .row {
  display: flex;
  justify-content: space-between;
}

.opening-hours-list-container .icon-container {
  min-width: 3rem;
}

.locations-container .day-name {
  text-transform: capitalize;
}

.error-text {
  color: red;
  padding-bottom: 10px;
}